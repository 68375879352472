import React,  { Component } from 'react';
// import withStyles from '@material-ui/styles/withStyles';
import { withRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
// import Paper from '@material-ui/core/Paper';
// import Typography from '@material-ui/core/Typography';
// import Grid from '@material-ui/core/Grid';
// import Button from '@material-ui/core/Button';
// import InstructionDialog from './dialogs/InstructionDialog';
// import SwipeDialog from './dialogs/SwipeDialog';

// import Recruiter from './recruiter/MainRecruiter';

import Recruiter from './recruiter/DashboardRecruiter';
import Candidate from './candidate/DashboardCandidate';

class Main extends Component {
    state = {
        type: 'recruiter',
        auth: true,

    }


    render () {
        return (
            <React.Fragment>
                <CssBaseline />
            { this.state.type === 'recruiter' ? <Recruiter/> : <Candidate/> }
            {/* <Candidate/> */}

            
            </React.Fragment>
        )
    }
}
export default withRouter(Main);